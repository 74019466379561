<template>
  <div class="content_box">
    <!-- 顶部图片 -->
    <div class="head_img_box">
      <el-image :src="headImg">
        <div slot="placeholder" class="image-slot">
          <img :src="loadImg" class="loadImg" alt="" />
        </div>
      </el-image>
      <!-- 使用方法 -->
      <div class="usage_box">
        <div class="title">후쿠이 지역의 매력적인 관광지와 인기 시설을 대상으로 한 가성비 있는 티켓입니다. 유효 기간 내에 대상 시설 중에서 원하는 시설을 3곳 선택하여 이용하실 수 있습니다. E-티켓에 기재된 QR 코드를 제시하시면 간편하게 입장할 수 있습니다.</div>
        <div class="syff">
          <div class="syff_left">
            <p></p>
            <p></p>
          </div>
          <div class="txt">사용방법</div>
          <div class="syff_right">
            <p></p>
            <p></p>
          </div>
        </div>
        <div class="neiron">
          ● 사용방법 : 예약 후 메일로 1장의 PDF를 수령한 후, 이용기간 중 해당 시설에 방문하여 QR코드를 제시한 뒤 교환해 주세요. <br>
          ● 각 시설은 1회 한정으로 이용 가능하며, 동일 시설에 재입장하실 수 없습니다. ● 이용 개시 가능일 : 구입일로부터 <span>90일간</span> <br>
          예시 : <span>7/1</span> 구입 ⇒ <span>이용 가능 기간 : 7/1~9/28</span> <br>
          ● 해당 패스는 첫 번째 시설 이용부터 <span>7일간</span> 유효합니다. <br>
          예시 : 첫 번째 시설 이용일 <span>7/1</span> ⇒ <span>유효기간 7/1~7/7</span> <br>
          ● 이용 가능 시설 : 유효 기간(7일) 내 <span>3개까지 </span> 이용 가능<br>
          ● 1회 주문당 여러 개의 패스를 구입하는 경우, 모든 패스의 유효 기간은 동일하므로 주의하시기 바랍니다. (<span>이용 개시부터 7일간</span>) <br>
          ● 유효 기간은 이용 개시부터 7일간이지만, 구입일로부터 <span>90일</span> 이후에는 이용하실 수 없으므로 주의하시기 바랍니다. <br>
          예시 : 구입일이 <span>7/1</span>인 경우, <span>7/1~9/28</span>에만 이용 가능합니다. <span>9/27</span>부터 이용 개시하셔도 <span>9/28</span>까지만 이용하실 수 있습니다. <br>
          ● 미사용 시설은 환불되지 않습니다. <br>
          ● 각 시설의 주의사항, 영업시간, 정기휴일 등은 다음 링크에서 확인해 주세요. 
        </div>
      </div>
      <!-- 景点介绍 -->
      <div class="main_box" v-for="(item,i) in dataList" :key="i">
        
        <!-- 图片 -->
        <div class="img_box">
          <el-image :src="item.img" lazy>
            <div slot="placeholder" class="image-slot">
              <img :src="loadImg" class="loadImg" alt="" />
            </div>
          </el-image>
        </div>
        
        <!-- 文字 -->
        <div class="text_box">
          <div class="title" v-for="(title,i) in item.title" :key="i" v-html="title"></div>
          <hr>
          <div class="message_box">
            <div class="message_item" v-for="msg in item.message" :key="msg.msgId">
              <div style="margin-bottom:5px">{{msg.title}}</div>
              <div v-if="msg.title!=='주의사항'">
                <div v-for="contentItem in msg.content" :key="contentItem.cid">
                  <span v-for="(cItem,i) in contentItem.text" :key="i">
                    <span v-show="msg.title==='티켓교환장소' && !contentItem.noColor">{{cItem}}<br></span>
                    <a :style="[{'borderBottom': !contentItem.noColor ? '1px solid' : ''},{color: !contentItem.noColor ? '#219DCC' : ''}]" target="_blank" :href="cItem" v-show="msg.title==='공식 사이트' && !contentItem.noColor">{{cItem}}<br></a>
                    <p v-show="contentItem.noColor" v-html="cItem"></p>
                  </span>
                </div>
              </div>
              <!-- 注意事项特殊处理 -->
              <!-- :style判断是否要数字前缀 -->
              <div :style="{marginLeft:msg.isDecimal ? '16.5px' : '0px'}" v-else>
                <ul :style="{listStyleType:msg.isDecimal ? 'decimal' : 'none'}">
                  <li v-for="contentItem in msg.content" :key="contentItem.cid">
                    <span v-html="cItem" v-for="(cItem,i) in contentItem.text" :key="i"></span>
                  </li>
                </ul>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 景点介绍 end -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      headImg: require('@/assets/images/hffukui/kr/havefun_title-kr.jpg'),
      loadImg: require('@/assets/images/hfkansai/loading.png'),
      dataList: [
        {
          id: 1,
          img: require('@/assets/images/hffukui/01_EN.jpg'),
          title: ['에치젠 철도 1일 무제한 승차권'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '1-1-1, Chuo, Fukui-shi, Fukui (에치젠 철도 후쿠이역 창구)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://www.echizen-tetudo.co.jp/'] },
                { cid: 2, text: ['이용 가능한 노선도:'], noColor:true },
                { cid: 3, text: ['https://www.echizen-tetudo.co.jp/route/'] },
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['상품 내용 : 에치젠 철도 미쿠니아와라선, 가쓰야마에이헤이지선을 1일 무제한으로 이용하실 수 있습니다.','<br>※대상 외：노선버스, 후쿠이철도'] },
                { cid: 2, text: ['반드시 무제한 승차권으로 교환 후 이용해 주세요.'] },
                ]
            }
          ]
        },
        // 
        {
          id: 2,
          img: require('@/assets/images/hffukui/02_EN.jpg'),
          title: ["후쿠이역의 5개 점포에서 이용 가능! 소바를 저렴하게 먹자♪ 소바 할인 쿠폰"],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '1-2-1, Chui, Fukui-shi, Fukui (해피링 1층 종합카운터)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://www.happiring.com/event/detail.php?cd=1409'] },
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['이용 가능 점포 : 소바 전문점 마루하치, 에치젠 소바 미요시야본점, 아미다소바 후쿠노이점, 후쿠이시 관광물산 후쿠부쿠칸 후쿠부쿠차야, 에치젠 소바클럽'] },
                { cid: 2, text: ['영업시간은 사전에 공식 홈페이지에서 확인해 주세요.','<br>※임시 휴업, 만석 등으로 인해 이용할 수 없는 경우가 있습니다.'] },
                { cid: 3, text: ['해피링 1층 종합카운터로 오셔서 QR코드를 제시해 주세요. 쿠폰으로 교환해 드립니다.'] },
                { cid: 4, text: ['해당 점포에 방문하셔서 식사 주문 시, 할인 쿠폰을 함께 제시해 주세요. 계산 금액에서 이용 매수의 금액만큼 할인해 드립니다.','<br※할인 가능한 대상 메뉴는 점포에 따라 다릅니다.>','<br>※점포에 따라 사용할 수 있는 쿠폰의 매수가 다릅니다.'] },
                { cid: 5, text: ['쿠폰 1장에 300엔 할인 쿠폰이 4장 포함되어 있습니다.'] },
                { cid: 6, text: ['쿠폰(실권)의 유효기간은 교환일로부터 반년입니다.'] },
                ]
            }
          ]
        },
        // 
        {
          id: 3,
          img: require('@/assets/images/hffukui/03_EN.jpg'),
          title: ['JR후쿠이역 앞 복합 빌딩 "해피링" 1,000엔 쿠폰'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '1-2-1, Chui, Fukui-shi, Fukui (해피링 1층 종합카운터)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://www.happiring.com/korean/'] },
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['1000엔 쿠폰을 이용하실 수 있습니다.'] },
                { cid: 2, text: ['계산 시 각 점포에 쿠폰을 제시해 주세요. (QR코드 제시로는 이용하실 수 없습니다. 반드시 쿠폰권으로 교환하신 후 각 점포에서 이용해 주세요.)'] },
                { cid: 3, text: ['본 쿠폰은 현금으로 교환이 불가하며 남은 잔돈은 드리지 않습니다.'] },
                { cid: 4, text: ['해당 점포 이외에서는 사용할 수 없습니다. (쿠폰 대상 외 매장 : 복권 판매점, 패밀리마트, SEIREN PLANET)'] },
                { cid: 5, text: ['쿠폰(실권)의 유효기간은 교환일로부터 반년입니다.'] },
                ]
            }
          ]
        },
        // 
        {
          id: 4,
          img: require('@/assets/images/hffukui/04_EN.jpg'),
          title: ['마루오카성 입장권'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '1-59, Kasumi-cho, Maruoka-cho, Sakai-shi, Fukui (마루오카성 매표소)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://www.fuku-e.com/spot/detail_1018.html'] },
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['상품 내용 : "마루오카성", "마루오카 역사민속자료관", "일필계상 일본 제일의 짧은 편지의 관"의 입장료'] },
                { cid: 2, text: ['영업시간은 사전에 공식 홈페이지에서확인해 주세요.'] },
                { cid: 3, text: ['재입장은 불가합니다.'] },
                ]
            }
          ]
        },
        // 
        {
          id: 5,
          img: require('@/assets/images/hffukui/05_EN.jpg'),
          title: ['아와라 온천에 위치한「미마쓰」1일 입욕권 (타월 대여 포함)'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '26-10 Funatsu Awara-shi Fukui (1층 프런트)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://www.mimatu.net/'] },
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['상품 내용 : 온천 입욕료, 목욕 타월 대여료'] },
                { cid: 2, text: ['영업시간은 사전에 공식 홈페이지에서 확인해 주세요.'] },
                { cid: 3, text: ['다이요덴은 남성, 메이게쓰덴은 여성 전용 온천이며, 입욕은 1회 한정으로 이용 가능합니다.'] },
                ]
            }
          ]
        },
        // 
        {
          id: 6,
          img: require('@/assets/images/hffukui/06_EN.jpg'),
          title: ['에치젠 마쓰시마 수족관 입장권 1500엔 할인 쿠폰'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '74-2-3, Saki, Mikuni-cho, Sakai-shi, Fukui (창구)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://www.echizen-aquarium.com/index.html'] },
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['1500엔 할인 쿠폰을 이용하실 수 있습니다. (입장권 구입 시에만 사용 가능)','<br>※남은 잔돈은 드리지 않습니다.'] },
                { cid: 2, text: ['영업시간은 사전에 공식 홈페이지에서 확인해 주세요.'] },
                { cid: 3, text: ['태풍, 폭설, 재해 등으로 임시 휴관 및 영업 시간이 변경될 수 있습니다. 사전에 양해 부탁드립니다.'] }
                ]
            }
          ]
        },
        // 
        {
          id: 7,
          img: require('@/assets/images/hffukui/07_EN.jpg'),
          title: ['공룡박물관 입장권 (상설 전시 관람권)'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '51-11, Terao, Muroko-cho, Katsuyama-shi, Fukui (본관 종합 안내소)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://www.dinosaur.pref.fukui.jp/'] },
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['영업시간은 사전에 공식 홈페이지에서 확인해 주세요.'] },
                { cid: 2, text: ['전시 변경 및 시설 점검으로 인해 임시 휴관을 하는 경우가 있습니다.'] },
                { cid: 3, text: ['입장권에 기재된 날짜 내라면 재입장 가능합니다.'] },
                ]
            }
          ]
        },
        // 
      ]
    }
  },
  created () {
    // this.$router.push('/hfPage?id=HFFukui&l=kr')
    window.open('https://www.travelcontentsapp.com/have-fun/have-fun-in-fukui-1-week-free-pass/kr', '_self')
  }
}
</script>
<style lang="scss" scoped>
.content_box {
  font-family: "Genseki";
  position: relative;
  width: 100%;
  height: 100%;
  // 使用方法
  .usage_box {
    background-color: #FFFBA0;
    padding: 18px 20px;
    margin: 20px 15px;
    color: #1A1311;
    font-weight: 700;
    margin-top: 0;
    .title{
      font-size: 13px;
      line-height: 19px;
    }
    .syff {
      display: flex;
      justify-content: space-between;
      margin: 18px 0;
      .txt {
        font-size: 21px;
        margin: 0 8.5px;
      }

      .syff_left,
      .syff_right {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        p{
          display: inline-block;
          height: 1px;
          width: 100%;
          background-color: #707070;
          margin-bottom: 2px;
        }
      }
    }
    .neiron {
      line-height: 21px;
      font-size: 12px;
      span{
        color: #FA6460;
      }
    }
  }
  .main_box {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 26px;
    .img_box {
      width: 100%;
      box-shadow: 6px 0px 6px rgba(0, 0, 0, 0.16);
    }
    
    .text_box {
      position: relative;
      margin-top: 22px;
      padding: 0 10px;
      // width: 90%;
      // padding-left: 24px;
      // padding-right: 17px;
      .title {
        font-size: 17px;
        font-weight: bolder;
        color: #876b57;
        margin: 5px 0;
        /deep/span {
          color:#ff0000;
        }
      }
      .message_box {
        font-size: 13px;
        line-height: 17px;
        font-weight: bold;
        margin-top: 5px;
        .message_item:not(:first-child) {
          margin-top: 22px;
        }
      }
    }
  }
}
.loadImg {
  width: 100%;
  height: auto;
}
hr {
  border: none;
  height: 1px;
  background-color: #707070;
}
a:link {
  color: #000;
}
a:visited {
  color: #000;
}
a:hover {
  color: #000;
  border-bottom: 1px solid;
}
a:active {
  color: #000;
}
</style>